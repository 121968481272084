<template>
    <div class="beer-smart page">
        
        <section class="duhaj-top-block">
        <div class="container">            
            <div class="row">
                <div class="col-12" v-inview="'fade-up'">
                    <div class="duhaj-logo-wrap">
                        <img class="img-responsive center-block duhaj-logo" src="@/assets/images/edu/logo.svg" alt="Soproni Óvatos Duhaj logo" />
                    </div>
                    <h1><span>Az Óvatos Duhajok 4 alapszabálya:</span></h1>
                    <div class="gold-arrows"></div>
                </div>
            </div>
        </div>
    </section>
    <section class="duhaj-rules">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- rule - 1 -->
                    <div class="rules">
                        <div class="rules__img" v-inview="'fade-from-left'">  
                            <picture>
                                <source srcset="@/assets/images/edu/anim_01_570x570.webp" type="image/webp">
                                <source srcset="@/assets/images/edu/rule_1.jpg" type="image/jpeg"> 
                                <img src="@/assets/images/edu/rule_1.jpg" alt="">
                            </picture>
                        </div>
                        <div class="rules__text" v-inview="'fade-from-right'">                            
                            <div class="number">1.</div>
                            <div class="text-wrap">
                                <div class="title">Tartsd a sört hűtőben, lehetőleg a terméken jelzett ajánlott hőfokon.</div>
                                <div class="text">A sörrel akkor is törődni kell, ha elhagyja a gyár kapuját. Legjobban úgy őrizheted meg a minőségét, ha a vásárlás után rögtön helyet keresel neki a hűtődben. Akkor is érdemes betenned, ha csak hetek vagy hónapok múlva tervezed elfogyasztani. A tejet sem hagyod kint szobahőmérsékleten, ugye?! Gondolj ugyanígy a sörödre. Ha meg szeretnéd őrizni az ízét, és elkerülnéd az oxidációt, tárold a terméken jelzett hőfokon addig, amíg el nem jött az ideje, hogy belekortyolj. </div>
                            </div>                                                                               
                        </div>
                    </div>
                    <!-- rule - 2 -->
                    <div class="rules">            
                        <div class="rules__img" v-inview="'fade-from-right'">                            
                            <picture>
                                <source srcset="@/assets/images/edu/anim_02_570x570.webp" type="image/webp">
                                <source srcset="@/assets/images/edu/rule_2.jpg" type="image/jpeg"> 
                                <img src="@/assets/images/edu/rule_2.jpg" alt="">
                            </picture>
                        </div>           
                        <div class="rules__text" v-inview="'fade-from-left'">                                                    
                            <div class="number">2.</div>                                               
                            <div class="text-wrap">
                                <div class="title">Óvd a söröket a napfénytől!</div>
                                <div class="text">Az UV sugárzás olyan kémiai reakciókat indíthat el kedvenc italodban, amelyek hatással lehetnek az íz élményre. Ne tedd fényre! Majd kimész vele a napra, amikor iszod.</div>
                            </div>                                                             
                        </div>
                        
                    </div>
                     <!-- rule - 3 -->
                    <div class="rules">          
                        <div class="rules__img" v-inview="'fade-from-left'">                            
                            <picture>
                                <source srcset="@/assets/images/edu/anim_03_570x570.webp" type="image/webp">
                                <source srcset="@/assets/images/edu/rule_3.jpg" type="image/jpeg"> 
                                <img src="@/assets/images/edu/rule_3.jpg" alt="">
                            </picture>
                        </div>             
                        <div class="rules__text" v-inview="'fade-from-right'">                            
                            <div class="number">3.</div>                                               
                            <div class="text-wrap">
                                <div class="title">Tárold a sört álló pozícióban!</div>
                                <div class="text">Egyszerű fizika: akkor érintkezik a legkevesebb levegővel a söröd, ha álló helyzetben tartod. Kevesebb levegő pedig kevesebb oxidációhoz vezet. Bingó! Tehát tedd be állítva a hűtőbe és tovább tartja meg a minőségét. </div>
                            </div>                                                             
                        </div>                       
                    </div>
                    <!-- rule - 4 -->
                    <div class="rules">          
                        <div class="rules__img" v-inview="'fade-from-right'">                            
                            <picture>
                                <source srcset="@/assets/images/edu/anim_04_570x570.webp" type="image/webp">
                                <source srcset="@/assets/images/edu/rule_4.jpg" type="image/jpeg"> 
                                <img src="@/assets/images/edu/rule_4.jpg" alt="">
                            </picture>
                        </div>             
                        <div class="rules__text" v-inview="'fade-from-left'">                            
                            <div class="number">4.</div>                                               
                            <div class="text-wrap">
                                <div class="title">Fogyasztás előtt a szűretlen söröket, mint az IPA, APA, vagy Búza forgasd meg egy kicsit.</div>
                                <div class="text">Gurítgasd az asztalon vagy lögyböld meg az alját. Ez azért fontos, hogy az esetlegesen leülepedett élesztőt újra összekeverjük a sörrel. így lesz mindig egyformán telt és zamatos. </div>
                            </div>                                                             
                        </div>                       
                    </div>
                </div>              
            </div>
        </div>
    </section>
    </div>
</template>

<script>
export default {
    mounted(){
        this.setMetaTags({
            title: 'Soproni - sör kisokos', 
            desc: null, 
            keywords: null, 
            ogType: 'website', 
            ogTitle: 'Soproni - sör kisokos',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc: null
        })
    }
}
</script>